import React, { Component } from 'react';
import moment from 'moment';
import axiosApi from '../../../axiosApi';
import AdSense from 'react-adsense';

//import { Button, Card, Row, Col, Image, CardTitle } from 'react-materialize';
//import moment from 'moment';
//import { Pie } from 'react-chartjs-2';
//import { Link }  from 'react-router-dom';

//Paginas
import Menu from '../../../template/menuBlog/index';
import Footer from '../../../template/footerBlog/index';
import './index.css';
import { defaultFormat } from 'moment';

/* img */

//function nl2br(texto) {
//  return texto.replace(new RegExp('\r?\n','g'), '<br />');
//}

class Home extends Component{
      constructor(props){
        super(props)
        this.state = {
          carregou:'0',
          mensagem:'Seja bem vindo. Temos novas Ofertas para você!',
          propaganda: [],
          artigos: [],
          maisLidos: [],
          plataforma: '',
          currentItem: 0,
         
        }
        localStorage.setItem('idArtigo', '');
        
        this.getArtigos();
        //this.setAcesso();
        

        //pageView.log('home');
      }
      
      setAcesso = async () => {
        var body = "";
        if (navigator.userAgentData != undefined && navigator.userAgentData.mobile) {
          body = "mobile";
        }else{
          body = "desktop";
        }

        await axiosApi.put("/acessoBlog/" + body, {
        })        
        .then(res => {        
            //cadastrado
            console.log("acesso realizado");
        })
        .catch(error => {
          console.log(error);
        })    
        
      }

      getPropaganda = async () => {
        await axiosApi.get("/propaganda", 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        })        
        .then(res => {        
            //Dados Pessoais
            this.setState({
             "propaganda": res.data.prog,
            });
        })
        .catch(error => {
          console.log(error);
        })    
        
      }

      getArtigos = async () => {
        await axiosApi.get("/artigo", 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        })        
        .then(res => {        
            //Dados Pessoais
            this.setState({
             "artigos": res.data.artigo,
            });
            this.getArtigosMaisLidos();

        })
        .catch(error => {
          console.log(error);
        })    
        
      }

      getArtigosMaisLidos = async () => {
        await axiosApi.get("/artigo/maislidos", 
        {
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
          },
        })        
        .then(res => {        
            //Dados Pessoais
            this.setState({
             "maisLidos": res.data.artigo,
            });
            this.getPropaganda()
        })
        .catch(error => {
          console.log(error);
        })    
        
      }

      getGerarNumAleatorio(valor){
        return Math.random() * ((valor - 1) - 0) + 0;
      }

      componentDidMount(){
        const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.sidenav');
          var instances = M.Sidenav.init(elems, {});
        });

        document.addEventListener('DOMContentLoaded', function() {
          var elems = document.querySelectorAll('.tooltipped');
          var instances = M.Tooltip.init(elems, {});
        });
        
        //document.querySelectorAll('.indicator .with-gap')[0].checked = true
        
      }

      toastMensagem(message){
        const M = window.M;
        M.toast({html: message, classes: 'rounded'})
      }

      retornaPorcentagem(like, dislike){
        let porcentagem = 0;
        porcentagem = (like / (like + dislike)) * 100;
        if(!porcentagem){
          porcentagem = 0;
        }
        return Math.round(porcentagem);
      }

      retornaViews(views){
        let view = 0;
        if(views >= 1000){
          view = Math.round(views/1000) + "k";
        }
        if(views >= 1000000){
          view = Math.round(views/1000000) + "M";
        }
        if(views >= 1000000000){
          view = Math.round(views/1000000) + "B";
        }
        if(views < 1000){
          view = views;
        }
        return view;
      }

      retornaData(data){
        let mes = ["meses", "Jan", "Fev", "Mar", "Abr", "Mai", " Jun", "Jul", "Ago", "Set", "Out",
      "Nov", "Dez"]
        data = moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
        let dia = data.substr(0,2);
        let numMes = parseInt(data.substr(3,5));
        let ano = data.substr(6,9);
        let date = "";
        let now = new Date();
        now = moment(now, "YYYY-MM-DD").format("DD/MM/YYYY")
        let diff = moment(data,"DD/MM/YYYY").diff(moment(now,"DD/MM/YYYY"));
        let dias = moment.duration(diff).asDays();
        if(data == now){
          date = "Hoje";
        }else if(dias == -1){
          date = "Ontem";
        }else{
          date = dia + " " + mes[numMes] + " " + ano;
        }
        return date;
      }

      retornaTextoCortado(texto, tipo){
        // 0 = Titulo esquerda
        // 1 = Descrição Esquerda
        // 2 = Titulo direita
        let textoAlterado = "";
        if(tipo == 0 && texto){
          if(texto.length < 112){
            textoAlterado = texto.substr(0,112);
          }else{
            textoAlterado = texto.substr(0,108) + "...";
          }
          
        }else if(tipo == 1 && texto){
          if(texto.length < 162){
            textoAlterado = texto.substr(0,162);
          }else{
            textoAlterado = texto.substr(0,159) + "...";
          }
          
        }else if(tipo == 2 && texto){
          if(texto.length < 110){
            textoAlterado = texto.substr(0,110);
          }else{
            textoAlterado = texto.substr(0,107) + "...";
          }
          
        }
        return textoAlterado;
      }

      lerArtigo = e =>{
        e.preventDefault();
        //localStorage.setItem('idArtigo', e.target.id);
        window.location.assign("/artigo/" + e.target.id);
      }

      compartilhar = e => { 
        e.preventDefault();
        let varia = e.target.id
        varia = varia.split(';')
        console.log(varia)
        if (navigator.userAgentData != undefined && navigator.userAgentData.mobile) {
          navigator.share({
            title: varia[0].trim(),
            text: varia[1].trim(),
            url: 'https://blog.breese.com.br/artigo/'+ varia[2].trim()
          })
          .then()
          //.then(() => console.log('Successfully shared! <3'))
          //.catch(() => console.log('Oh oh! Something went wrong:', error));
          .catch(() => this.toastMensagem('Oh oh! Aconteceu algo de errado ;('));
        }else{
          navigator.clipboard.writeText('https://blog.breese.com.br/artigo/'+ varia[2].trim());
          this.toastMensagem('Link Copiado. . .');
        }
        
      } 

      carouselMoviment= (e) =>{
        e.preventDefault()
        let {currentItem} = this.state
        const control = e.target.name
        const items = document.querySelectorAll('.item')
        const maxItems = items.length;

        if(control === "left"){
          if(currentItem === 0)
            currentItem = maxItems - 1;
          else
            currentItem --;
        }else{
          if(currentItem === maxItems - 1)
            currentItem = 0;
          else
            currentItem ++;
        }

        this.setState(
          {
            currentItem: currentItem
          }
        )

        items.forEach(item =>{
          item.classList.remove('current-item')
          items[currentItem].scrollIntoView({
            inline:"center",
            behavior: "smooth",
          })
          items[currentItem].classList.add('current-item')
        })
        document.querySelectorAll('.indicator .with-gap')[currentItem].checked = true
      }

      indicatorsCarousel = (e) =>{
        let currentItem = e.target.value;
        const items = document.querySelectorAll('.item')
        this.setState(
          {
            currentItem: currentItem
          }
        )
        items.forEach(item =>{
          item.classList.remove('current-item')
          items[currentItem].scrollIntoView({
            block: "end",
            inline:"center",
            behavior: "smooth",
          })
          items[currentItem].classList.add('current-item')
        })
      }

      render(){
        const {artigos, maisLidos, propaganda} = this.state;
        return(
          <div className="homeBlog"> 
          <Menu />
          <section className='propaganda-header'>
            <div align="center">
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-0882179401889472"
      crossOrigin="anonymous"></script>
            </div>
          </section>
            <main>
              <article>
                <div className='container'>
                  <p className='indicator'>
                    {maisLidos.map((art, i) =>(
                      <label key={i}>
                        <input className="with-gap" name="group3" type="radio" value={i} onClick={this.indicatorsCarousel}/>
                        <span></span>
                      </label>
                    ))}
                  </p>
                  
                  <button onClick={this.carouselMoviment} name="left" className='arrow-left control material-icons' aria-label='Previous image'> arrow_back_ios</button>

                  <button onClick={this.carouselMoviment} name="right" className='arrow-right control material-icons' aria-label='Next image'>arrow_forward_ios</button>

                  <div className='gallery-wrapper'>
                    <div className='gallery'>
                      {maisLidos.map(artigo =>(
                          <div className='item current-item' key={artigo._id} onClick={this.lerArtigo} id={artigo.url}>
                            <div className='content-image' onClick={this.lerArtigo} id={artigo.url}>
                              <img src={artigo.imgGrande} className="responsive-img" onClick={this.lerArtigo} id={artigo.url} />
                            </div>
                            <div className='content-text'  onClick={this.lerArtigo} id={artigo.url}>
                              <p className='title'  onClick={this.lerArtigo} id={artigo.url}> 
                                {this.retornaTextoCortado(artigo.titulo, 0)}
                              </p>
                              <p className='texto'onClick={this.lerArtigo} id={artigo.url}>
                                {this.retornaTextoCortado(artigo.descricao, 1)}
                              </p>
                            </div>
                          </div>
                        
                      ))}  
                    </div>                    
                  </div>                  
                </div>
              </article>
              
              <article className=''>
                {artigos.map(artigo =>(
                      <div className="card" key={artigo._id} id={artigo.url} >
                        <div className="card-content top" id={artigo.url}>
                            <span className='date' id={artigo.url}>{this.retornaData(artigo.createdAt)}</span>

                            <span className='textIcon' id={artigo.url}>{this.retornaViews(artigo.views)}</span>
                            <i className="material-icons" id={artigo.url}>visibility</i>

                            <span className='textIcon' id={artigo.url}>{this.retornaPorcentagem(artigo.like, artigo.dislike)}%</span><i className="material-icons" id={artigo.url}>grade</i>
                        </div>
                        <div className="card-image"  id={artigo.url} >
                          <img className="responsive-img" src={artigo.imgGrande}  alt={artigo.titulo} onClick={this.lerArtigo} id={artigo.url} />
                          <span className="card-title"  onClick={this.lerArtigo} id={artigo.url}>{this.retornaTextoCortado(artigo.titulo, 0)}</span>

                          <a className="btn-floating left halfway-fab waves-effect waves-light" onClick={this.lerArtigo} id={artigo.url} title="Ler Artigo">
                            <i className="material-icons" id={artigo.url} >preview</i>
                          </a>
                          <a className="btn-floating halfway-fab waves-effect waves-light" title='Compartlhar artigo' onClick={this.compartilhar} id={artigo.titulo + ";" + artigo.descricao + "; " + artigo.url}>
                            <i className="material-icons" id={artigo.titulo + ";" + artigo.descricao + "; " + artigo.url}>share</i>
                          </a>

                        </div>
                        <div className="card-content" onClick={this.lerArtigo} id={artigo.url}>
                          <p onClick={this.lerArtigo} id={artigo.url}>
                            {this.retornaTextoCortado(artigo.descricao, 1)}
                          </p>
                        </div>
                      </div>
                ))}
                <div className="card propaganda">
                <div align="center">
                  
                  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-0882179401889472" crossOrigin="anonymous"></script>
              </div>
                </div>
                  
              </article>
                
              <article className='lateral'>
               <ul className="collection">
                  <li className="collection-item categoria"> 
                      <span className="title">
                        Mais acessados
                      </span>
                  </li>
                  <li className="collection-item"> 
                    <a href='/pesquisar/artigos/lei' >
                      <span className="title">
                        Leis
                      </span>
                    </a>
                  </li>
                  <li className="collection-item"> 
                    <a href='/pesquisar/artigos/contabilidade' >
                      <span className="title">
                        Contábeis
                      </span>
                    </a>
                  </li>
                  <li className="collection-item"> 
                    <a href='/pesquisar/artigos/MEI' >
                      <span className="title">
                        MEI
                      </span>
                    </a>
                  </li>

                  <li className="collection-item propaganda">     
                    <div align="center propaganda">
                      {
                        propaganda.length
                          ?
                            <a href={propaganda[0].url} target="_blank" title={"Comprar: " + propaganda[0].titulo}>
                              <img className='responsive-img' src={propaganda[0].imgQuadrada} title="Clique para Comprar"/>
                            </a>
                          :
                            ''
                      }
                      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-0882179401889472"
      crossOrigin="anonymous"></script>
                    </div>
                  </li>
                </ul>
              </article>
            </main>

            <section className='propaganda-header'>
              <div align="center">
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-0882179401889472" crossOrigin="anonymous"></script>
              </div>
            </section>
            <Footer />
        </div>
      );
    }
}
export default Home;