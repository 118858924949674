import React, { Component } from 'react';

import {Sidenav, Dropdown} from 'materialize-css';
//Paginas
import './index.css';
import M from 'materialize-css';

// Imagens
import logoImg from '../../assets/logoBlog.png';

class Footer extends Component{
    
    componentDidMount(){
        //const M = window.M;
        document.addEventListener('DOMContentLoaded', function() {
            var elems = document.querySelectorAll('.sidenav');
            var instances = M.Sidenav.init(elems, {});
          });
        
    }
    
    render() {
        return(
            <div className="page-foolter">                    
                <div className="container">                
                    <a  href="https://www.instagram.com/bree.se_/" target="_blank" title='Instagram Bree.se'>
                        Instagram
                    </a>
                    <span> | </span>
                    <a  href="https://dashboard.breese.com.br" target="_blank" title='Dashboard Bree.se'>
                        Dashboard
                    </a>
                    <span> | </span>
                    <a  href="https://breese.com.br" target="_blank" title='Site Bree.se'>
                        Site
                    </a>
                    
                </div>
                <div className="footer-copyright">
                    <div className="container-cop">                        
                        <span className="left" >bree.suporteempresarial@gmail.com</span>
                        <span className="right" >Copyright © 2022 Bree Suporte Empresarial</span>
                    </div>
                </div>
            </div>
        );
    };
};
export default Footer;