import React, { Component } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';



//BLOG
import HomeBlog from './component/blog/home.component/index';
import PesquisarBlog from './component/blog/pesquisar.component/index';
import artigoBlog from './component/blog/artigo.component/index';
import adminBlog from './component/blog/admin.component/index';

class Router extends Component{
    constructor(props){
        super(props)
        this.state = {
          /*carregou:'0',
          mensagem:'Seja bem vindo. Temos novas Ofertas para você!',
          status: localStorage.getItem('idStatus'),
          id: localStorage.getItem('idAluno'),
          token: localStorage.getItem('X-Cookie-Status')*/
        }
      } 
      
      render(){
          return(
            <BrowserRouter>
                <Switch>                       
                    <Route path="/authenticate/admin/home"  component={adminBlog} />                     
                    <Route path="/pesquisar/artigos/:slug"  component={PesquisarBlog} /> 
                    <Route path="/artigo/:slug"  component={artigoBlog} /> 
                    <Route path="/"  component={HomeBlog} />                      
                    <Route component={HomeBlog} />                      
                </Switch>   
            </BrowserRouter>
          );
      
    }
}
export default Router;